var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-tabs',{attrs:{"centered":"","color":_vm.accentColor},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$t('hooks_create.create')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$t('hooks_create.duplicate')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-card-text',{staticStyle:{"padding":"0px"}},[_c('MutationForm',{attrs:{"no-reset":"","mutation":"createHook","constants":{ environmentId: _vm.environmentId },"submitButtonText":_vm.$t('hooks_create.submitButtonText'),"submitButtonIcon":"add","submit-button-color":_vm.accentColor,"environment-variables":{ environmentId: _vm.environmentId },"schema":{
            environmentId: {
              __graphQLType: 'ID',
            },
            name: {
              label: _vm.$t('hooks_create.schema.name.label'),
              type: 'string',
              description: _vm.$t('hooks_create.schema.name.description'),
              fieldOptions: {
                color: _vm.accentColor,
              },
              newDesign: true,
            },
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('hooks_create.cancelBtn')))])],1)],1)],1),_c('v-tab-item',[_c('v-card-text',[_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('hooks_create.duplicates')))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('hooks_create.whatDuplicate')))]),_c('div',{staticClass:"overline"},[_vm._v(_vm._s(_vm.$t('hooks_create.WontDupli')))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('hooks_create.whatWontDupli1')))]),_c('div',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t('hooks_create.whatWontDupli2')))]),_c('MutationForm',{attrs:{"no-reset":"","mutation":"duplicateHook","environmentVariables":{ environmentId: _vm.environmentId },"submitButtonText":"Duplicar","submitButtonIcon":"content_copy","submit-button-color":_vm.accentColor,"schema":{
            hookId: {
              __graphQLType: 'ID',
              label: _vm.$t('hooks_create.schema.hookId.label'),
              fieldType: 'componentSelect',
              fieldOptions: {
                componentTypeName: 'hooks',
              },
              newDesign: true,
            },
            newName: {
              label: _vm.$t('hooks_create.schema.newName.label'),
              type: 'string',
              description: _vm.$t('hooks_create.schema.newName.description'),
              fieldOptions: {
                color: _vm.accentColor,
              },
              newDesign: true,
            },
          }},on:{"success":function($event){return _vm.$emit('create', $event)},"error":function($event){return _vm.$emit('error', $event)}}},[_c('v-btn',{attrs:{"slot":"left-buttons","dark":"","color":"#59a4f4"},on:{"click":function($event){return _vm.$emit('dismiss')}},slot:"left-buttons"},[_vm._v(_vm._s(_vm.$t('hooks_create.cancelBtn')))])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }